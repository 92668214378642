import { FC, memo } from 'react';
import cn from 'classnames';

import BinaryStorageImage from 'components/BinaryStorageImage';
import PackIcon, { IconsPack } from 'components/PackIcon';
import { SvgIconSize } from 'components/SvgIcon';

interface Props {
    logo: string;
}

const FeaturedTournamentsLogo: FC<Props> = ({ logo }) => {
    const imageSize = cn('h-[18px] w-[18px]');

    return (
        <div className="flex h-6 w-6 items-center justify-center rounded-default bg-surface-light">
            {logo ? (
                <div className={imageSize}>
                    <BinaryStorageImage
                        src={logo}
                        width={18}
                        height={18}
                        className="h-full"
                    />
                </div>
            ) : (
                <PackIcon
                    id="top-tournament"
                    pack={IconsPack.SpriteIcons}
                    className="fill-grey-500"
                    size={SvgIconSize.WH_4}
                />
            )}
        </div>
    );
};

export default memo(FeaturedTournamentsLogo);
