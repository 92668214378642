import React from 'react';

import EventsCounter from 'components/betting/Categorizer/CategorizerDesktop/CategorizerEventsCounter';
import BinaryStorageImage from 'components/BinaryStorageImage';
import FlagIcon from 'components/FlagIcon';
import SmartLink from 'components/SmartLink';
import { Action, Category } from 'services/GoogleAnalytics';
import { getFormattedCountryCode } from 'utils';
import { FeaturedConfig } from '../../types';

interface Props {
    config: FeaturedConfig;
}

const FeaturedTournamentsRow: React.FC<Props> = ({ config }) => {
    const { name, linkToTournament, countryCode, sportEventCount, logo } =
        config;

    return (
        <SmartLink
            href={linkToTournament}
            className="flex cursor-pointer justify-between gap-2 bg-surface-dark p-3 text-grey-500 hover:bg-surface-middle hover:text-primary-white"
            analyticsAttrs={{
                'data-category': Category.FiltersFeatured,
                'data-action': Action.SelectTournament,
            }}
        >
            <div className="flex items-center gap-2 overflow-hidden">
                {countryCode ? (
                    <FlagIcon
                        countryCode={getFormattedCountryCode(countryCode)}
                        className="shrink-0"
                    />
                ) : (
                    <div className="flex h-4 w-4 shrink-0 items-center">
                        <BinaryStorageImage src={logo} width={32} height={32} />
                    </div>
                )}
                <div className="truncate typo-sm-1">{name}</div>
            </div>
            <EventsCounter count={sportEventCount || 0} />
        </SmartLink>
    );
};

export default FeaturedTournamentsRow;
