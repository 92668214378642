import { useGetSportTypeByPath, useToggle } from 'hooks';
import { FeaturedConfig } from '../types';
import useFeatureTournaments from '../useFeatureTournaments';
import { getZoneIdFromLocation } from '../utils';

interface Output {
    loading: boolean;
    visibleConfigs: Array<FeaturedConfig>;
    isExpanded: boolean;
    isShowExpandButton: boolean;
    toggleExpand: () => void;
}

const DEFAULT_CONFIG_LIST_SIZE = 5;

const useFeaturedTournamentsDesktop = (): Output => {
    const sportType = useGetSportTypeByPath();

    const tournamentZoneId = getZoneIdFromLocation(sportType);

    const { loading, transformedZoneConfigs } =
        useFeatureTournaments(tournamentZoneId);

    const [isExpanded, toggleExpand] = useToggle(false);

    const isShowExpandButton =
        transformedZoneConfigs.length > DEFAULT_CONFIG_LIST_SIZE;

    const visibleConfigs = isExpanded
        ? transformedZoneConfigs
        : transformedZoneConfigs.slice(0, DEFAULT_CONFIG_LIST_SIZE);

    return {
        loading,
        visibleConfigs,
        isExpanded,
        isShowExpandButton,
        toggleExpand,
    };
};

export default useFeaturedTournamentsDesktop;
