import React, { memo } from 'react';
import cn from 'classnames';
import { capitalize } from 'lodash';

interface Props {
    title: string;
    icon: JSX.Element;
    isActive?: boolean;
    itemsCount?: number;
    onClick?: VoidFunction;
    classNames?: string;
}

const LeftSidebarDesktopItem: React.FC<Props> = ({
    title,
    icon,
    itemsCount,
    isActive,
    onClick,
    classNames,
}) => {
    return (
        <div
            className={cn(
                'flex justify-between bg-surface-dark p-2 hover:bg-surface-middle',
                {
                    '!bg-surface-middle': isActive,
                },
                classNames
            )}
            onClick={onClick}
        >
            <div
                className={cn(
                    'flex items-center gap-2 uppercase typo-sm-2',
                    isActive
                        ? 'fill-primary-orange-toxic text-primary-orange-toxic'
                        : 'fill-primary-white text-primary-white'
                )}
            >
                {icon}
                {capitalize(title)}
            </div>
            {!!itemsCount && (
                <div className="flex items-center">
                    <div className="mr-1 flex h-4 min-w-[16px] items-center justify-center rounded-[8px] bg-surface-light px-1 py-[2px] text-grey-500 typo-xs-2">
                        {itemsCount}
                    </div>
                </div>
            )}
        </div>
    );
};

export default memo(LeftSidebarDesktopItem);
