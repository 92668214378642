import React from 'react';
import cn from 'classnames';
import { upperCase } from 'lodash';
import { useTranslations } from 'next-intl';

import LeftSidebarDesktopItem from 'components/betting/LeftSidebarDesktop/LeftSidebarDesktopItem';
import PackIcon, { IconsPack } from 'components/PackIcon';
import { Action, Category } from 'services/GoogleAnalytics';
import FeaturedTournamentsRow from './FeaturedTournamentsRow';
import useFeaturedTournamentsDesktop from './useFeaturedTournamentsDesktop';

const FeaturedTournamentsDesktop: React.FC = () => {
    const { visibleConfigs, isShowExpandButton, toggleExpand, isExpanded } =
        useFeaturedTournamentsDesktop();

    const t = useTranslations('betting-global.global');
    const icon = <PackIcon id="top-events" pack={IconsPack.SpriteIcons} />;

    if (!visibleConfigs.length) return null;

    const expandLabel = upperCase(isExpanded ? t('hide') : t('showMore'));

    return (
        <div className="my-4 border-y-default border-surface-middle py-4">
            <LeftSidebarDesktopItem
                title={t('featured')}
                icon={icon}
                classNames="pointer-events-none"
            />
            <>
                {visibleConfigs.map((config) => (
                    <FeaturedTournamentsRow key={config.slug} config={config} />
                ))}
                {isShowExpandButton && (
                    <div
                        className={cn(
                            'mx-auto flex w-[fit-content] cursor-pointer items-center justify-center py-2 pl-[21px] pr-2 font-normal typo-sm-1 hover:bg-surface-middle',
                            isExpanded
                                ? 'fill-primary-orange-toxic text-primary-orange-toxic'
                                : 'fill-primary-white text-primary-white'
                        )}
                        onClick={toggleExpand}
                        data-category={Category.FiltersFeatured}
                        data-action={Action.ToggleMore}
                    >
                        {expandLabel}
                        <PackIcon
                            id="branch-more-up"
                            pack={IconsPack.SpriteIcons}
                            className={cn(
                                isExpanded ? 'rotate-0' : 'rotate-180'
                            )}
                        />
                    </div>
                )}
            </>
        </div>
    );
};

export default FeaturedTournamentsDesktop;
