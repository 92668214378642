import { OverlayType, POLLING_INTERVAL } from 'app-constants';
import { useRouterQuery } from 'hooks';
import { useResponsive } from 'layouts/MainLayout/ResponsiveLayoutContext';
import { isSsr } from 'utils/isSsr';
import { FeaturedTournamentZone } from './constants';
import { useGetFeaturedTournaments } from './getFeaturedTournaments.bet';
import { TransformedFeaturedConfigsType } from './types';
import { getTransformedFeaturedConfigs } from './utils';

interface Output {
    transformedZoneConfigs: TransformedFeaturedConfigsType;
    loading: boolean;
    isActive: boolean;
}

const useFeatureTournaments = (id: FeaturedTournamentZone): Output => {
    const { overlayType } = useRouterQuery();
    const { until } = useResponsive();
    const isMobile = until('md');

    const isActive = overlayType === OverlayType.FEATURED_TOURNAMENTS_LIST;
    const shouldSkipFeaturedTournaments = isMobile && !isActive;

    const { data, loading } = useGetFeaturedTournaments(() => ({
        variables: {
            zoneId: id,
        },
        fetchPolicy: 'cache-and-network',
        returnPartialData: true,
        skip: shouldSkipFeaturedTournaments,
        pollInterval: POLLING_INTERVAL,
        pagePropsKey: 'featuredTournaments',
    }));

    const withSportEventCountValidate = !isSsr() && !loading;

    const transformedZoneConfigs = data?.featuredZoneConfigs?.length
        ? getTransformedFeaturedConfigs(
              data.featuredZoneConfigs,
              withSportEventCountValidate
          )
        : [];

    return {
        loading,
        isActive,
        transformedZoneConfigs,
    };
};

export default useFeatureTournaments;
