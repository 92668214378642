import { fillLink } from 'cl-fill-link';

import { AppLink, ID_PATH_DEVIDER, SportType } from 'app-constants';
import type { GetFeaturedTournaments } from 'types/gql.bet';
import { FeaturedTournamentZone } from './constants';
import {
    FeaturedConfig,
    FeaturedProviderConfigType,
    TransformedFeaturedConfigsType,
} from './types';

export const getZoneIdFromLocation = (
    sportType?: SportType
): FeaturedTournamentZone => {
    switch (sportType) {
        case SportType.Sports: {
            return FeaturedTournamentZone.ClassicLeftWeb;
        }

        case SportType.Esports: {
            return FeaturedTournamentZone.EsportLeftWeb;
        }

        default: {
            return FeaturedTournamentZone.MixLeftWeb;
        }
    }
};

const getFeaturedConfig = (
    config: GetFeaturedTournaments['featuredZoneConfigs'][number]
): FeaturedConfig | null => {
    switch (config.__typename) {
        case 'FeaturedZoneTournamentProvider': {
            if (!config.tournament) return null;

            const {
                priority,
                sportEventCount,
                tournament: {
                    slug,
                    name,
                    description,
                    logo,
                    sportId,
                    countryCode,
                },
            } = config;

            // TODO: Get sport type by sport slug
            const sportType = sportId.includes('esports_')
                ? SportType.Esports
                : SportType.Sports;

            const linkBySportType =
                sportType === SportType.Esports
                    ? AppLink.ESPORTS_TOURNAMENT_SLUG
                    : AppLink.SPORTS_TOURNAMENT_SLUG;

            const linkToTournament = fillLink(linkBySportType, {
                slug,
            });

            return {
                type: FeaturedProviderConfigType.Tournament,
                slug,
                name,
                description,
                logo,
                priority,
                sportEventCount,
                linkToTournament,
                countryCode,
            };
        }

        // TODO: Remove support for custom list
        // case 'FeaturedZoneCustomListProvider': {
        //     const sportType = FEATURED_SPORT_TYPE_MAPPING[config.sportType];

        //     const linkToTournament = fillLink(AppLink.SPORT_TYPE_SPORT_ID, {
        //         sportType,
        //         sportId: config.slug,
        //     });

        //     return {
        //         type: FeaturedProviderConfigType.CustomList,
        //         slug: config.slug,
        //         name: config.title,
        //         description: config.description,
        //         logo: config.image,
        //         priority: config.priority,
        //         linkToTournament,
        //     };
        // }

        default:
            return null;
    }
};

const getFeaturedConfigKey = (slug: string, type: string): string =>
    `${slug}${ID_PATH_DEVIDER}${type}`;

export const getTransformedFeaturedConfigs = (
    configs: GetFeaturedTournaments['featuredZoneConfigs'],
    withSportEventCountValidate: boolean
): TransformedFeaturedConfigsType => {
    return configs
        .reduce((acc: FeaturedConfig[], config) => {
            const transformedConfig = getFeaturedConfig(config);

            if (
                !transformedConfig?.slug ||
                (withSportEventCountValidate &&
                    transformedConfig.type ===
                        FeaturedProviderConfigType.Tournament &&
                    !transformedConfig.sportEventCount)
            ) {
                return acc;
            }

            const key = getFeaturedConfigKey(
                transformedConfig.slug,
                transformedConfig.type
            );

            acc.push({
                ...transformedConfig,
                slug: key,
            });

            return acc;
        }, [])
        .sort((a, b) => a.priority - b.priority);
};
