export interface FeaturedConfig {
    type: string;
    countryCode?: string;
    slug: string;
    name: string;
    description: string;
    logo: string;
    priority: number;
    sportEventCount?: number;
    linkToTournament: string;
}

export enum FeaturedProviderConfigType {
    CustomList = 'CUSTOM_LIST',
    Tournament = 'TOURNAMENT',
}
export type TransformedFeaturedConfigsType = Array<FeaturedConfig>;
