import React from 'react';

import { useGetSportTypeByPath } from 'hooks';
import useFeatureTournaments from '../useFeatureTournaments';
import { getZoneIdFromLocation } from '../utils';
import FeaturedTournamentsRow from './FeaturedTournamentsRow';

const FeaturedTournamentsMobile: React.FC = () => {
    const sportType = useGetSportTypeByPath();

    const tournamentZoneId = getZoneIdFromLocation(sportType);

    const { transformedZoneConfigs, isActive } =
        useFeatureTournaments(tournamentZoneId);

    if (!isActive) return null;

    return (
        <div className="mx-2 mt-2 md:mx-0">
            {transformedZoneConfigs.map((config) => (
                <FeaturedTournamentsRow key={config.slug} config={config} />
            ))}
        </div>
    );
};

export default FeaturedTournamentsMobile;
