import React from 'react';

import SmartLink from 'components/SmartLink';
import { Action, Category } from 'services/GoogleAnalytics';
import { FeaturedConfig } from '../../types';
import FeaturedTournamentsLogo from '../FeaturedTournamentsLogo';

interface Props {
    config: FeaturedConfig;
}

const FeaturedTournamentsRow: React.FC<Props> = ({ config }) => {
    const { name, logo, linkToTournament } = config;

    return (
        <SmartLink
            href={linkToTournament}
            className="flex gap-2 p-2 hover:bg-surface-middle"
            analyticsAttrs={{
                'data-category': Category.FiltersFeatured,
                'data-action': Action.SelectTournament,
            }}
        >
            <FeaturedTournamentsLogo logo={logo} />
            <div className="flex flex-1 items-center overflow-hidden text-primary-white">
                <div className="max-w-full truncate font-normal typo-sm-1">
                    {name}
                </div>
            </div>
        </SmartLink>
    );
};

export default React.memo(FeaturedTournamentsRow);
